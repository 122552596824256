import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField } from "@mui/material";
import { post } from "../../service/apiService";

function AlertDialog(props: any) {
  const [open, setOpen] = React.useState(props.open);
  const { handleCancel, onRenew, handleCloseDialog, err } = props;
  const [text, setText] = React.useState(
    props.text ? props.text : "هل متأكد من إلغاء الإشتراك ؟"
  );
  const [title, setTitle] = React.useState(
    props.title ? props.title : "إلغاء الإشتراك"
  );
  const userData = localStorage.getItem("userProfileDto");
  const userId = userData ? JSON.parse(userData).id : null;

  const handleClose = () => {
    handleCloseDialog();
  };
  const [value, setValue] = React.useState("");
  React.useEffect(() => {
    setText(props.text ? props.text : "هل متأكد من إلغاء الإشتراك ؟");
    setTitle(props.title ? props.title : "إلغاء الإشتراك");
  }, [props.text, props.title]);

  const onAgree = () => {
    handleCancel();
    setOpen(false);
  };
  const onContinue = () => {
    onRenew(value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  React.useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="flex flex-col">{title}</div>
        </DialogTitle>
        <DialogContent>
          {props.renewText === "renew" ? (
            <div className=" ">
              <div className="mb-3">{text}</div>
              <TextField
                label="الرجاء ادخال الرمز هنا"
                type="number"
                value={value}
                onChange={handleChange}
                slotProps={{ htmlInput: { maxLength: 4 } }}
                className="w-4"
                variant="outlined"
                fullWidth
              />
              {err && <div className="text-danger">{err}</div>}
            </div>
          ) : (
            text
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.renewText === "renew" ? () => onContinue() : onAgree}
            autoFocus
          >
            {props.renewText === "renew" ? "متابعة" : "متأكد"}
          </Button>
          <Button onClick={handleClose}>
            {props.renewText === "renew" ? "إلغاء" : "لست متأكد"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AlertDialog;
