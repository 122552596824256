import * as React from "react";

import { get, post } from "../service/apiService";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Pagination } from "@mui/material";
function AdminDashboard() {
  const auth = { userName: "Admin", password: "i5SGT-LbSy[R${<W" };
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: pageSize,
  });

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "identityId",
      headerName: "Identity ID",
      width: 200,
      editable: false,
    },
    {
      field: "isCanceled",
      headerName: "Subscritpion cancelled",
      type: "boolean",
      width: 200,
      editable: false,
    },
    {
      field: "profileStatus",
      headerName: "Profile status",
      width: 200,
      editable: false,
    },
    {
      field: "days",
      headerName: "Days left",
      width: 150,
      editable: false,
    },
    {
      field: "startDate",
      headerName: "Start date",
      width: 200,
      editable: false,
    },
    {
      field: "endDate",
      headerName: "end date",
      width: 200,
      editable: false,
    },
    {
      field: "chargingMethod",
      headerName: "Payment method",
      width: 200,
      editable: false,
    },
  ];

  React.useEffect(() => {
    window.addEventListener("beforeunload", handleLeave);

    post("identity/Login/dashboard", { ...auth })
      .then((response) => {
        localStorage.setItem("accessToken", response.accessToken);
        localStorage.setItem("isAdmin", "yes");
        console.log("inside");
        get(
          `/SubscriptionHistory?pageNumber=${pageNumber}&pageSize=${pageSize}`
        )
          .then((response) => {
            console.log("inside inside ");
            console.log("here", response);
            const tempData = response.data.map((item, index) => {
              return {
                ...item,
                startDate: item.startDate.split("T")[0],
                endDate: item.endDate.split("T")[0],
                id: index + 1,
              };
            });
            setData(tempData);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
    return handleLeave();
  }, []);

  const handleLeave = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("isAdmin");
  };

  const handlePageChange = (event, value) => {
    setPageNumber(value);
    get(`/SubscriptionHistory?pageNumber=${value}&pageSize=${pageSize}`)
      .then((response) => {
        const tempData = response.data.map((item, index) => {
          return {
            ...item,
            startDate: item.startDate.split("T")[0],
            endDate: item.endDate.split("T")[0],
            id: index + 1,
          };
        });
        setData(tempData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Box
      sx={{
        height: 400,
        width: "100%",
        direction: "rtl",
      }}
    >
      <DataGrid
        rows={data}
        columns={columns}
        paginationModel={paginationModel}
        pageSizeOptions={[1, 2, 3]}
        disableRowSelectionOnClick
        rowCount={pageSize}
        disableColumnFilter
        disableColumnSorting
        disableColumnMenu
        sx={{
          direction: "rtl", // Set direction to RTL
          "& .MuiDataGrid-cell": {
            textAlign: "right", // Align text to the right
          },
        }}
      />
      <Pagination count={10} page={pageNumber} onChange={handlePageChange} />
    </Box>
  );
}

export default AdminDashboard;
