import * as React from "react";
import Dialog from "@mui/material/Dialog";
import {
  Button,
  Chip,
  DialogActions,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { get, update, post } from "../../service/apiService";
import ConfirmSnackBar from "../snackbar/ConfrimSnackBar";
import AlertDialog from "./AlertDialog";

function SubscriptionDialog(props: any) {
  const { onClose, open } = props;
  const [data, setData] = React.useState<any>();
  const userData = localStorage.getItem("userProfileDto");
  const userId = userData ? JSON.parse(userData).id : null;
  const [startDate, setStartDate] = React.useState<any>();
  const [endDate, setEndDate] = React.useState<any>();
  const [confrimSubmit, setConfrimSubmit] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>();
  const [openAlert, setOpenAlert] = React.useState<boolean>(false);
  const cancelSubscriptionText = " إلغاء الإشتراك";
  const renewSubscriptionText = "تجديد الإشتراك";
  const [renewText, setRenewText] = React.useState<any>();
  const [title, setTitle] = React.useState<string>();
  const [text, setText] = React.useState<string>();
  const [snackbarText, setSnackbarText] =
    React.useState<string>("تمت العملية بنجاح");

  const [alertErr, setAlertErr] = React.useState<string>("");

  const formatDates = (startDate: string, endDate: string) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const formattedStartDate = start.toLocaleDateString("ar");
    const formattedEndDate = end.toLocaleDateString("ar");
    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
  };
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    setSnackbarText(snackbarText);
  }, [renewText]);
  React.useEffect(() => {
    get(`Profile/%20${userId}/Subscription`)
      .then((res: any) => {
        setData(res);
        formatDates(res.startDate, res.endDate);
      })
      .catch((err) => {});
  }, [open]);
  const handleClose = () => {
    setConfrimSubmit(false);
    setError("");
    onClose();
  };
  const activeStateToArabic = (state: string) => {
    if (state === "Active") {
      return "نشط";
    } else {
      return "غير نشط";
    }
  };
  const handleCanacel = () => {
    setLoading(true);
    update(`Profile/${userId}/unsubscribe`, {
      unsubscribeReason: "some reason",
      delete: false,
    })
      .then((res) => {
        setConfrimSubmit(true);
        setOpenAlert(false);
        setTimeout(() => {
          setLoading(false);
          handleClose();
        }, 3000);
      })
      .catch((err) => {
        setConfrimSubmit(false);
        setLoading(false);

        if (err.response?.message) {
          setError(err.response.message);
          return;
        } else {
          setError("حدث خطأ");
        }
      });
  };

  const onRenew = (value: any) => {
    setLoading(true);
    const bd = {
      id: userId,
      otp: value,
      deviceType: "web",
    };
    post("Profile/renew-subscription-confirm", bd)
      .then((res) => {
        setOpenAlert(false);
        setConfrimSubmit(true);
        setTimeout(() => {
          setRenewText(null);
          setLoading(false);
          handleClose();
        }, 3000);
      })
      .catch((err) => {
        setConfrimSubmit(false);
        setLoading(false);

        if (err.response?.data) {
          setAlertErr(err.response.data);
          return;
        } else {
          setAlertErr("حدث خطأ");
        }
      });
  };

  const handleRenewClicked = () => {
    setLoading(true);
    post(`/Profile/renew-subscription`, {
      id: userId,
    })
      .then((res) => {
        setLoading(false);
        setTitle("تجديد الإشتراك");
        setText(" تم ارسال رمز التحقق الى رقم الهاتف الخاص بك");
        setOpenAlert(true);
        console.log("changed");
        setRenewText("renew");
      })
      .catch((err) => {
        setConfrimSubmit(false);
        setLoading(false);
        console.log("res error", err);
        if (err.response?.data) {
          setError(err.response.data);
          return;
        } else {
          setError("حدث خطأ");
        }
      });
  };
  const handleCloseDialog = () => {
    setAlertErr("");
    setOpenAlert(false);
  };

  console.log("open alert", snackbarText);
  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle id="alert-dialog-title">{"حالة الإشتراك"}</DialogTitle>
        {data && (
          <div className="px-3 d-flex flex-column">
            <div className="mb-2">
              <span className="fw-bold mt">{`الحالة : `} </span>
              <span
                className={
                  data.profileStatus === "Active"
                    ? "active-color"
                    : "danger-color"
                }
              >
                {activeStateToArabic(data.profileStatus)}
              </span>
            </div>
            <div className="mb-2">
              <span className="fw-bold ">{`عدد الايام : `}</span>
              <span>{data.days}</span>
            </div>
            <div className="mb-2">
              <span className="fw-bold ">{`تاريخ بداية الاشتراك : `}</span>
              <span>{startDate}</span>
            </div>
            <div className="mb-2">
              <span className="fw-bold ">{`تاريخ انتهاء الاشتراك : `}</span>
              <span>{endDate}</span>
            </div>
            <div className="text-danger">{error}</div>
          </div>
        )}
        <DialogActions>
          {data && data.profileStatus === "Active" ? (
            <Button onClick={() => setOpenAlert(true)} disabled={loading}>
              {cancelSubscriptionText}
            </Button>
          ) : (
            <Button
              onClick={() => {
                handleRenewClicked();
              }}
              disabled={loading}
            >
              {renewSubscriptionText}
            </Button>
          )}
          <Button onClick={handleClose}>إغلاق</Button>
        </DialogActions>
        <ConfirmSnackBar
          open={confrimSubmit}
          text={snackbarText}
          duration={2000}
          position={{ vertical: "top", horizontal: "center" }}
        />
      </Dialog>
      <AlertDialog
        open={openAlert}
        handleCancel={handleCanacel}
        renewText={renewText}
        title={title}
        text={text}
        onRenew={onRenew}
        handleCloseDialog={handleCloseDialog}
        err={alertErr}
      />
    </>
  );
}

export default SubscriptionDialog;
